$button-color: #5894C6;
$button-color-secondary: green;


.modal-container {
  
  .modal-btn {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $button-color;
        border-radius: 10rem;
        z-index: -2;
    };
    
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($button-color, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        &:before {
        width: 100%;
        }
    }
  }

  .modal-btn-secondary {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $button-color-secondary;
        border-radius: 10rem;
        z-index: -2;
    };
    
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($button-color-secondary, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        &:before {
        width: 100%;
        }
    }
  };
  

  .modal-content, 
  .modal-backdrop {
    height: 0; 
    width: 0; 
    opacity: 0;    
    visibility: hidden;
    overflow: hidden; 
    cursor: pointer;
    transition: opacity 0.2s ease-in; 
  }
  .modal-close {
    color: #aaa;
    position: absolute;
    right: 5px;
    top: 5px;
    padding-top: 3px;
    background: #fff;
    font-size: 16px;
    width: 25px;
    height: 25px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    &:hover { color: #333; }        
  }      
  .modal-content-btn {
    position: absolute;
    text-align: center;
    cursor: pointer;
    bottom: 20px;
    right: 30px;
    background: #446CB3;
    color: #fff;
    width: 100px;
    border-radius: 15px;
    font-size: 14px;
    height: 32px;
    padding-top: 9px;
    font-weight: bold;
    &:hover { color: #fff; background: #365690; }
  }
  #modal-toggle {
    display: none;  
    &.active ~ .modal-backdrop,
    &:checked ~ .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      visibility: visible;
      opacity: 1;      
      transition: opacity 0.2s ease-in; 
    }
    &.active ~ .modal-content,
    &:checked ~ .modal-content {
      opacity: 1;
      background-color: #fff;
      max-width: 400px;
      width: 400px;
      height: 37.5rem;
      padding: 10px 30px;
      position: fixed;
      left: calc(50% - 200px);
      top: 12%;
      border-radius: 4px;
      z-index: 999;
      pointer-events: auto;
      cursor: auto;
      visibility: visible;      
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);  
      @media (max-width: 400px) { left: 0; };
      //overflow-y: auto;               
    }  
  }
};

